import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import { FeatureFlagContext } from 'lib/ContextTypes';

/**
 * Inserts the OLI AI chat bot script in Paris Olympics pages
 *
 * @param {Array} taxonomy - the taxonomy to search through to see if the page is valid to show the bot
 * @param {Boolean} isChromeless - flag to identify if a page is chromeless/app view
 * @param {Boolean} isHomepage - flag to identify if is the homepage
 * @returns {React.ReactElement}
 */
const OliChatBot = ({ taxonomy, isChromeless, isHomepage }) => {
  // visibility is controlled both by a LaunchDarkly feature flag
  // and by a url param to be able to toggle on even if the FF is off (for prod testing)
  const { 'oli-chat-bot': isOliEnabled } = useContext(FeatureFlagContext);
  const [oliParam, setOliParam] = useState(false);

  useEffect(() => {
    // get url param flag in the client render
    const urlParams = new URLSearchParams(window?.location?.search);
    setOliParam(urlParams.get('oli-chat'));
  }, []);

  // don't show if flags turned off or if in chromeless view
  if (isChromeless || (!isOliEnabled && !oliParam)) return null;


  // determine if page has the correct Paris Olympics taxonomy attached
  const taxonomyList = taxonomy.map((t) => t.path || t);
  const hasOlympicsTaxonomy = taxonomyList.includes('today/section/news/paris-olympics') || taxonomyList.includes('today/section/news/sports/olympics');

  return (hasOlympicsTaxonomy || isHomepage) ? (
    <Head>
      <script>
        {`
        var oliEl = document.createElement('div');oliEl.setAttribute("id", "oli-chat");oliEl.setAttribute("data-activity-map", "oli-chat");document.body.appendChild(oliEl);
        var script = document.createElement('script'); script.src = "/oli/oly-concierge.min.2.js"; document.body.appendChild(script);
        `}
      </script>
    </Head>
  ) : null;
};

OliChatBot.propTypes = {
  taxonomy: PropTypes.array,
  isChromeless: PropTypes.bool,
  isHomepage: PropTypes.bool,
};

OliChatBot.defaultProps = {
  taxonomy: [],
  isChromeless: false,
  isHomepage: false,
};

export { OliChatBot };
