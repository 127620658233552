import React from 'react';
import Head from 'next/head';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { loadPersonsAction } from 'redux/modules/person';
import { SWRConfig } from 'swr';
import { getDuration } from 'apicache';

import {
  loadFront as loadFrontAction,
  loadFrontDraft as loadFrontDraftAction,
  setDraftId,
} from 'redux/modules/front';
import { loadAutomatedWaffle } from 'redux/modules/waffle';
import { setTheme as setThemeAction } from 'redux/modules/navbar';
import {
  setPageView,
  setSection,
  setQuery,
  setVertical,
} from 'redux/modules/shared';

import Ad from 'components/Ad';
import AdsBundle from 'components/AdsBundle';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import ScrollingAnalytics from 'components/ScrollingAnalytics';
import getLayout from 'components/layouts/getLayout';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import { EventDayRecirc } from 'components/EventDayRecirc';
import { FrontMetadata, GlobalMetadata } from 'components/PageMetadata';
import IconfontStyleTag from 'components/IconfontStyleTag';
import UniversalCheckout from 'components/UniversalCheckout';
import { NorthForkScript } from 'components/NorthForkScript';
import { CommerceAmazonContentInsights } from 'components/Commerce/AmazonContentInsights';

import BTE from 'lib/BTE';
import Breakpoints from 'lib/Breakpoints';
import {
  curation as curationPropType,
} from 'lib/CustomPropTypes';
import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import { ScheduleDataPropType } from 'lib/CustomPropTypes/scheduleData';
import InactivityMonitor from 'lib/InactivityMonitor';
import { listenForCurationMessages } from 'lib/listenForCurationMessages';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import {
  getFirstLayout,
  getFirstPackageType,
  hasHeaderPackage,
  hasHeaderTransparentLightPackage,
  hasHeaderLightPackage,
} from 'lib/packageHelper';
import {
  getAllPackageTypes, getAllPackageSubTypes, getAllPackages, getPackagesByType,
} from 'lib/curationUtils';
import { setLinkHeaders } from 'lib/setLinkHeaders';
import { stripQueryParams } from 'lib/urlUtils';
import { getBroadcastSchedulesDataUrl, BROADCAST_SCHEDULES_ENDPOINT } from 'lib/scheduleUtils';
import { mapBentoAPIEndpointsToSWRUrls } from 'lib/mapBentoAPIEndpointsToSWRUrls';
import { getRamenBentoAPIUrl } from 'lib/getRamenBentoAPIUrl';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { FLUID_WIDTH_FRONT } from 'lib/brandFeatures';
import { logAction, logError } from 'lib/datadog';
import GatedFrontPage from 'components/GatedFrontPage';
import { OliChatBot } from 'components/OliChatBot';
import ErrorPage from '../_error';

import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import globalContainerStyles from '../globalContainerStyles.module.scss';
import './styles.themed.scss';

const pageView = 'front';

const mapStateToProps = ({
  front: {
    loading, curation, draftId,
  },
  shared: { isChromeless },
  navbar: { theme },
}) => ({
  loading,
  isChromeless,
  curation,
  draftId,
  navTheme: theme,
});

const mapActionsToProps = {
  loadFront: loadFrontAction,
  loadFrontDraft: loadFrontDraftAction,
  setTheme: (theme) => setThemeAction(theme),
};

function reloadWindow() {
  if (document.hasFocus()) window.location.reload(true);
}

export const navbarConfig = {
  theme: (props) => {
    const {
      content: {
        curation: {
          layouts,
        } = {},
      } = {},
      path,
      vertical,
    } = props;

    const isHomePage = stripQueryParams(path) === '/';
    if (vertical === 'select' && ['collectionsLead', 'waffle'].includes(getFirstPackageType(layouts))) {
      return NAVBAR_THEME.LIGHT;
    }
    if (vertical === 'today') {
      return NAVBAR_THEME.LIGHT;
    }
    if (hasHeaderTransparentLightPackage(layouts, vertical)) {
      return NAVBAR_THEME.TRANSPARENT_LIGHT;
    }
    if (isHomePage && hasHeaderLightPackage(layouts, vertical)) {
      return NAVBAR_THEME.LIGHT;
    }
    if (hasHeaderPackage(layouts)) {
      return NAVBAR_THEME.TRANSPARENT;
    }
    if (['news', 'msnbc', 'noticias'].includes(vertical)) {
      return NAVBAR_THEME.VERTICAL;
    }
    return NAVBAR_THEME.LIGHT;
  },
};

class Front extends React.Component {
  // eslint-disable-next-line react/sort-comp
  static getInitialProps = async (ctx) => {
    const {
      req: {
        query,
        params: {
          draftId,
          section = '',
        },
      },
      res: {
        locals: {
          cacheRequest,
          data: {
            curatedList: eventDayList,
          } = {},
        },
      },
      store,
    } = ctx;
    // Can be overwritten by news/sponsoredContent
    let { vertical } = ctx.res.locals;

    const {
      serverRuntimeConfig:
      { API_CACHE_TTL, SCHEDULE_DATA_API_CACHE_TTL, CURATION_API_REDIS_CACHE_TTL },
    } = getConfig();

    const promises = [
      store.dispatch(setPageView(pageView)),
      store.dispatch(setSection(section)),
    ];

    if (query && Object.keys(query).length) {
      promises.push(store.dispatch(setQuery(query)));
    }

    await Promise.all(promises);

    const frontPromises = [];

    if (draftId) {
      store.dispatch(setDraftId(draftId)); // set for later refreshes on curation
      frontPromises.push(store.dispatch(loadFrontDraftAction(draftId)));
    } else {
      frontPromises.push(store.dispatch(loadFrontAction(vertical, section, query)));
    }

    await Promise.all(frontPromises);

    const { front: frontPageStateAfterLoad } = store.getState();
    const pageType = frontPageStateAfterLoad?.curation?.pageType ?? 'front';
    const statusCode = frontPageStateAfterLoad?.error?.status ?? 200;
    const packageTypes = getAllPackageTypes(frontPageStateAfterLoad?.curation);
    const hasScheduleDataUsingPackage = packageTypes.has('liveVideoEmbed');
    const hasEntityHubPackage = packageTypes.has('entityHub');
    const hasWafflePackage = packageTypes.has('waffle');

    if (hasEntityHubPackage) {
      await Promise.resolve(store.dispatch(loadPersonsAction()));
    }

    // set logic to fetch custom data in the Automated Waffle package
    if (hasWafflePackage) {
      // only supports the first Waffle package on the page
      const wafflePkgData = getPackagesByType(frontPageStateAfterLoad?.curation, 'waffle')[0];
      // only run for Automated Waffle sub type
      if (wafflePkgData?.subType === 'automated') {
        await store.dispatch(loadAutomatedWaffle({ vertical, metadata: wafflePkgData.metadata }));
      }
    }

    const broadcastSchedulesDataUrl = getBroadcastSchedulesDataUrl();
    const scheduleDataTTL = getDuration(SCHEDULE_DATA_API_CACHE_TTL || API_CACHE_TTL);
    let scheduleData;

    if (hasScheduleDataUsingPackage) {
      try {
        scheduleData = await cacheRequest({
          url: broadcastSchedulesDataUrl,
          ttlms: scheduleDataTTL,
          cacheKey: ':BroadcastSchedules:',
        })
          .then(({ data }) => data);
      } catch (e) {
        // no op
      }
    }

    const showBlogPkgs = getAllPackages(frontPageStateAfterLoad?.curation)
      .filter((pkg) => pkg.subType === 'showBlog' && pkg.metadata.blogSource?.taxonomyPath);

    const showBlogPath = showBlogPkgs.length
      ? showBlogPkgs[0].metadata.blogSource.taxonomyPath // Support only one (first) showBlog per curation
      : null;

    const showBlogDataPath = `/latestShowBlogPosts/${showBlogPath}`;
    const showBlogDataUrl = `${getRamenBentoAPIUrl()}${showBlogDataPath}`;
    const showBlogDataTTL = getDuration(CURATION_API_REDIS_CACHE_TTL || API_CACHE_TTL);
    let showBlogData;

    if (showBlogPath) {
      try {
        showBlogData = await cacheRequest({
          url: showBlogDataUrl,
          ttlms: showBlogDataTTL,
          cacheKey: `:ShowBlogData:${showBlogPath}`,
        })
          .then(({ data }) => data);
      } catch (e) {
        // Noop: The show blog package will not render while the rest of the page will render.
      }
    }

    if (pageType === 'sponsoredContent') {
      vertical = 'sponsoredcontent';
      store.dispatch(setVertical(vertical));
      ctx.res.locals.vertical = vertical;
    }

    setLinkHeaders(ctx.res, vertical);

    return {
      draftId,
      pageType,
      pageView,
      section,
      statusCode,
      vertical,
      eventDayList,
      ramenBentoAPISWRFallbackData: {
        [BROADCAST_SCHEDULES_ENDPOINT]: scheduleData,
        [showBlogDataPath]: showBlogData,
      },
    };
  };

  top = null;

  static propTypes = {
    curation: curationPropType.isRequired,
    domain: PropTypes.string.isRequired,
    draftId: PropTypes.string,
    isChromeless: PropTypes.bool,
    layout: servicesLayoutPropType.isRequired,
    loadFrontDraft: PropTypes.func.isRequired,
    navTheme: PropTypes.string.isRequired,
    pageType: PropTypes.string,
    ramenBentoAPISWRFallbackData: PropTypes.shape({
      [BROADCAST_SCHEDULES_ENDPOINT]: ScheduleDataPropType,
    }).isRequired,
    section: PropTypes.string,
    setTheme: PropTypes.func.isRequired,
    statusCode: PropTypes.number.isRequired,
    vertical: PropTypes.string.isRequired,
    eventDayList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  };

  static childContextTypes = {
    section: PropTypes.string,
  };

  static defaultProps = {
    draftId: null,
    isChromeless: false,
    pageType: '',
    section: null,
    eventDayList: undefined,
  };

  getChildContext() {
    const { section } = this.props;
    return {
      section,
    };
  }

  componentDidMount() {
    const {
      curation: { layouts, pageRoute },
      draftId,
      loadFrontDraft,
      vertical,
    } = this.props;

    const lightNavPackages = hasHeaderLightPackage(layouts, vertical);
    const isNews = vertical === 'news';
    const isMsnbc = vertical === 'msnbc';
    const isNoticias = vertical === 'noticias';
    const isHomePage = pageRoute === '/';

    if ((isNews || isMsnbc || isNoticias) && !lightNavPackages && isHomePage) {
      BTE.on('scroll', this.handleScroll, 'throttle');
    }

    if (__CLIENT__) { // eslint-disable-line no-undef
      listenForCurationMessages(vertical, () => {
        if (!draftId) return; // no draft is loaded
        loadFrontDraft(draftId);
      });

      this.monitorActivity();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    window.parent.postMessage('refreshedFront', '*');
    logAction('refreshedFront', 'true');
  }

  componentWillUnmount() {
    BTE.remove('scroll', this.handleScroll);
    InactivityMonitor.stopMonitoring();
    InactivityMonitor.removeListener(reloadWindow);
  }

  setTop() {
    const { top } = this.container.getBoundingClientRect();
    this.top = top + (!Breakpoints.isS() ? window.pageYOffset - 80 : 0);
  }

  handleScroll = (depth) => {
    this.setTop();
    const { navTheme, setTheme } = this.props;

    if (depth > this.top && navTheme !== NAVBAR_THEME.LIGHT) {
      setTheme(NAVBAR_THEME.LIGHT);
    } else if (depth <= this.top && navTheme !== NAVBAR_THEME.VERTICAL) {
      setTheme(NAVBAR_THEME.VERTICAL);
    }
  };

  monitorActivity = () => {
    const { section, vertical } = this.props;
    let inactivityDuration;

    if (!section && ['news', 'msnbc', 'today', 'noticias'].includes(vertical)) {
      inactivityDuration = 4;
    }

    if (inactivityDuration) {
      InactivityMonitor.after((inactivityDuration * 60000), reloadWindow);
      InactivityMonitor.startMonitoring();
      logAction('monitorFrontActivityDuration', inactivityDuration);

      window.addEventListener('mousemove', InactivityMonitor.resetTimer);
      window.addEventListener('click', InactivityMonitor.resetTimer);
      window.addEventListener('scroll', InactivityMonitor.resetTimer);
      window.addEventListener('keypress', InactivityMonitor.resetTimer);
    }
  };

  interstitialAd = () => {
    const { vertical } = this.props;
    if (['news', 'today', 'msnbc', 'globalcitizen'].includes(vertical)) {
      return (
        <Ad
          slot="interstitial"
          refreshInterval={0}
        />
      );
    }
    return null;
  };

  renderLayouts({ isFluidWidthPage }) {
    const { curation, vertical, pageType } = this.props;

    if (!curation.layouts) return null;

    const {
      adsDisabled,
      autofill,
      layouts,
      pageRoute,
    } = curation;

    const layoutRenderer = getLayout({
      adsDisabled,
      curationAutofill: autofill,
      layouts,
      pageRoute,
      pageType,
      vertical,
      isFluidWidthPage,
    });

    return curation.layouts.map(layoutRenderer);
  }

  render() {
    const {
      vertical,
      curation,
      section, // need to get this to be passed in from routes.
      domain,
      isChromeless,
      layout,
      statusCode,
      eventDayList,
      ramenBentoAPISWRFallbackData,
    } = this.props;


    const isFluidWidthPage = getFeatureConfigForBrand(
      FLUID_WIDTH_FRONT,
      vertical,
    );

    if (statusCode === 404) {
      logError('frontStatusCode', '404');
      return (
        <ErrorPage statusCode={404} layout={layout} />
      );
    }

    if (statusCode !== 200) {
      logError('frontStatusCode', '500');
      return (
        <ErrorPage statusCode={500} layout={layout} />
      );
    }

    const {
      layouts,
      pageRoute,
    } = curation;
    let url = `https://www.${domain}`;
    if (['news', 'msnbc', 'today', 'sponsoredcontent'].includes(vertical) === false) {
      url += `/${vertical}`;
    }
    if (section) {
      url += `/${section}`;
    }


    const firstPackageType = getFirstPackageType(layouts);
    const hasHeaderPkg = hasHeaderPackage(layouts);
    let headerPkgs;
    if (hasHeaderPkg) {
      headerPkgs = `leadPkg-${getFirstLayout(layouts).packages[0].type}`;
    }

    const firstContentInZoneA = layouts?.[0]?.packages?.[0] ?? {};

    const layoutContainerClasses = classNames(
      'layout-container',
      {
        'flat-layout': curation.layouts && curation.layouts.length === 1,
        'header-package': hasHeaderPkg,
        'zone-a-margin': pageRoute === '/',
        'first-package-cover': firstPackageType === 'coverSpread',
      },
      `lead-type--${getFirstPackageType(layouts)}`,
      headerPkgs,
    );
    const hasShopThisList = getAllPackageSubTypes(curation) // TODO another method to get all the pkgs
      .has('shopListByOneProduct');

    const frontContent = (
      <ScrollingAnalytics contentType="front" contentUrl={url}>
        <div
          className={layoutContainerClasses}
          ref={(e) => { this.container = e; }}
          data-testid="front-container"
        >
          {this.interstitialAd()}

          <GatedFrontPage pageRoute={pageRoute} />
          {this.renderLayouts({ isFluidWidthPage })}
          <OliChatBot taxonomy={curation.autofill?.values} isHomepage={isFluidWidthPage && pageRoute === '/'} />
        </div>
      </ScrollingAnalytics>
    );
    const wrappedContent = isChromeless
      ? frontContent
      : (
        <HeaderAndFooter
          layout={layout}
          pageRoute={pageRoute}
          contentAboveHeader={(
            eventDayList?.content?.items?.length > 0
            && (
              <EventDayRecirc
                headline={eventDayList.headline}
                externalUrl={eventDayList.externalUrl}
                content={eventDayList.content}
              />
            )
          )}
        >
          {frontContent}
        </HeaderAndFooter>
      );

    const swrFallbackData = mapBentoAPIEndpointsToSWRUrls(ramenBentoAPISWRFallbackData);

    logAction('front-today-accounts-enabled', true);

    return (
      <SWRConfig
        value={{
          fallback: swrFallbackData,
        }}
      >
        <Head>
          <IconfontStyleTag />
        </Head>
        <ThemesCssBundle vertical={vertical} />

        <GlobalMetadata />
        <FrontMetadata
          curation={curation}
          firstContentInZoneA={firstContentInZoneA}
          vertical={vertical}
          section={section}
          domain={domain}
        />

        <div
          className={classNames(
            globalContainerStyles.container,
            {
              'bg-knockout-primary': !isFluidWidthPage,
            },
          )}
          id="content"
        >
          {wrappedContent}
        </div>

        <AdsBundle />
        <AnalyticsLaunchScripts />
        <UniversalCheckout vertical={vertical} />
        {hasShopThisList && (
          <>
            <NorthForkScript />
            <div className="nfw-anchor" data-widget="nongrocery-cart" data-article-page="true" />
          </>
        )}
        <CommerceAmazonContentInsights />
      </SWRConfig>
    );
  }
}

export default navbar(navbarConfig)(
  connect(mapStateToProps, mapActionsToProps)(Front),
);

export { Front as UnwrappedFront };
