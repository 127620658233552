import React, { useEffect, useRef } from 'react';
import { AUTHENTICATED } from 'lib/myNewsConstants';
import PropTypes from 'prop-types';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { GATED_CONTENT } from 'lib/brandFeatures';
import { useVertical } from 'lib/Hooks';
import { Gate } from 'components/Gate';
import { GATE_BANNER_TITLES, GATE_TITLES, GATE_SUBTITLES } from 'components/Gate/GateTextConstants';
import { stub as $t } from '@nbcnews/analytics-framework';
import throttle from 'lodash.throttle';
import styles from './styles.module.scss';
import { useMyNewsStore } from '../../store';

$t('register', 'mbt_gate_click');

const GatedFrontPage = ({ pageRoute }) => {
  const authenticationState = useMyNewsStore((state) => state.authenticationState);
  const authenticate = useMyNewsStore((state) => state.authenticate);

  const launchDarklyFlags = useFeatureFlagContext();
  const gateFrontPageFlag = launchDarklyFlags?.['today-gated-front'];

  const ref = useRef(null);

  const handleScroll = throttle(() => {
    if (!ref.current) return;
    const footerTopPosition = document.getElementById('hfs-footer')?.getBoundingClientRect().top;
    ref.current.style.position = footerTopPosition <= window.innerHeight ? 'absolute' : 'fixed';
  }, 10);

  useEffect(() => {
    const layoutContainers = document.querySelectorAll('.layout-container a');
    const tabindex = authenticationState !== AUTHENTICATED ? '-1' : '0';

    layoutContainers.forEach((a) => a.setAttribute('tabindex', tabindex));

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [authenticationState]);

  const { vertical } = useVertical();
  const hasGatedContentFeature = getFeatureConfigForBrand(
    GATED_CONTENT,
    vertical,
  );

  const track = (action) => {
    $t('track', 'mbt_gate_click', {
      action, // 'signup start' or 'login start' values to be used
      gatetype: 'inline', // this indicates the placement of the CTA.
    });
  };

  const showGate = (pageRoute && pageRoute === '/start-today' && hasGatedContentFeature)
      && authenticationState !== AUTHENTICATED && gateFrontPageFlag;
  if (!showGate) return null;

  return (
    <div
      className={styles.container}
      data-testid="gated-front-page"
      ref={ref}
    >
      <div
        className={styles.backdrop}
        aria-label="Trigger Signup"
        role="button"
        onKeyDown={() => {}}
        tabIndex="0"
        onClick={() => {
          authenticate({ defaultPage: 'createProfile' });
          track('signup start');
        }}
      />
      <div className={styles.banner}>
        <Gate
          bannerTitle={GATE_BANNER_TITLES.front}
          title={GATE_TITLES.front}
          subtitle={GATE_SUBTITLES.front}
          className={styles.gate}
          isSticky
        />
      </div>
    </div>
  );
};

GatedFrontPage.propTypes = {
  pageRoute: PropTypes.string.isRequired,
};

export default GatedFrontPage;
